import React, { useCallback } from 'react';
import { useSnackbarStyles } from './styled';
import { CustomContentProps, useSnackbar } from 'notistack';
import { Box } from '@mui/material';
import { Icon } from 'ui/components/Icon';
import clsx from 'clsx';
import FBOButton from '../FBOButton/FBOButton';
import { colorPalette } from 'ui/theme';

interface FBOSnackbarProps extends CustomContentProps {
  variant: 'success' | 'warning' | 'error' | 'info';
  message: string;
}

const setTitle = (variant: string): string => {
  return variant.charAt(0).toUpperCase() + variant.slice(1);
};

const FBOSnackbar = React.forwardRef<HTMLDivElement, FBOSnackbarProps>(
  ({ id, ...props }, ref) => {
    const { variant, message } = props;
    const classes = useSnackbarStyles(variant);
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <Box
        className={clsx(classes.main, {
          success: variant === 'success',
          warning: variant === 'warning',
          error: variant === 'error',
          info: variant === 'info',
        })}
        ref={ref}
      >
        <Box className={classes.icon}>
          <Icon
            name={`${variant === 'success' ? 'CheckCircle' : 'Info'}`}
            width={20}
            height={20}
          />
        </Box>
        <Box className={classes.contentContainer}>
          <h5 className={classes.heading}>{setTitle(variant)}</h5>
          <span className={classes.content}>{message}</span>
        </Box>
        <FBOButton
          className={classes.closeButton}
          variant="tertiary"
          color="neutral"
          size="small"
          icon="FBOClose"
          data-qa="client-snackbar-close"
          onClick={handleDismiss}
          sx={{ color: colorPalette.redesign.contentPrimary }}
        />
      </Box>
    );
  }
);

export default FBOSnackbar;
