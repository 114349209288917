import _ from 'lodash';

import {
  createApiReducer,
  chainReducers,
  INITIAL_API_STATUS,
} from 'services/api';

import { setAccessToken } from '../services';
import { AuthState } from './types';
import { AuthActionTypes } from './actions';

const initialState: AuthState = {
  token: null,
  _status: INITIAL_API_STATUS,
};

const auth = createApiReducer(
  [
    AuthActionTypes.SIGN_IN_START,
    AuthActionTypes.SIGN_IN_SUCCESS,
    AuthActionTypes.SIGN_IN_FAILURE,
  ],
  (data) => ({ token: data.token }),
  (data) => data.error.message
);

const signOut = createApiReducer(
  ['', AuthActionTypes.SIGN_OUT, AuthActionTypes.SIGN_OUT_ERROR],
  () => initialState,
  (error) => error.response.data.errorMessage
);

const authPersistor = createApiReducer(
  ['', AuthActionTypes.SIGN_IN_SUCCESS, ''],
  (data) => {
    setAccessToken(data.token);
  },
  _.noop
);

export default chainReducers(initialState, auth, signOut, authPersistor);
