import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel, Checkbox } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import _ from 'lodash';

import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { TextField } from 'ui/components/TextField/TextField';
import {
  COUNTRIES,
  CountryAutocomplete,
  CountryType,
} from 'ui/components/Autocomplete/CountryAutocomplete';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { DetailsCard } from 'ui/components/Page/DetailsCard';
import {
  StateAutocomplete,
  STATES,
  StateType,
} from 'ui/components/Autocomplete/StateAutocomplete';
import LocationsAsyncAutocomplete from 'ui/components/Autocomplete/LocationsAsyncAutocomplete/LocationsAsyncAutocomplete';
import {
  AddressValidationModal,
  VerifiedAddressLabel,
} from 'ui/components/Modal/AddressModal/components';
import {
  LocationType,
  Location,
  initialLocation,
  getLocations,
  postLocation,
  putLocation,
  deleteLocation,
  fetchLocations,
  fetchLocation,
  restoreLocation,
} from 'services/locations';
import { useHandleTextFieldChange } from 'services/forms';
import {
  Address,
  AddressValidation,
  initalValidAddress,
  initialAddress,
} from 'services/addresses';
import { postAddress, putAddress } from 'services/addresses/api';
import { getSettingsCompany } from 'services/settings/company/redux';
import { activeUserHasPermission } from 'services/user/redux';
import { Errors, validateYup } from 'services/forms/validation';
import { getShippingIntegrationConnection } from 'services/integrations/shipping/redux';
import {
  getValidateAddress,
  ShippingConnectionType,
} from 'services/integrations/shipping';
import { showNotification } from 'services/api';
import { PhoneInputField } from 'ui/components/TextField/PhoneInputField';

import { LocationDetailsCardProps } from './types';
import { emailBlurValidation, yupSchema } from './validations';
import { editLocationPermissions } from '../helpers';
import { LocationTitleBar } from '../LocationTitleBar';
import { logErrorCtx } from 'app/logging';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const FBOLocationDetailsCard: React.FC<LocationDetailsCardProps> = (props) => {
  const { activeLocationId, fetchSearchResult, onClose } = props;

  const { items: locations } = useSelector(getLocations);
  const companySettings = useSelector(getSettingsCompany);
  const connection = useSelector(
    getShippingIntegrationConnection(ShippingConnectionType.Shippo)
  );

  const dispatch = useDispatch();

  const [activeLocation, setActiveLocation] = useState(initialLocation);
  const [validationErrors, setValidationErrors] = useState<Errors>({});
  const [parentAddress, setParentAddress] = useState(initialAddress);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addressValidationModalVisible, setAddressValidationModalVisible] =
    useState<boolean>(false);
  const [addressValidation, setAddressValidation] =
    useState<AddressValidation>(initalValidAddress);

  const oldState = useRef<Location | null>(initialLocation);

  const firstInputElement = useRef<HTMLInputElement>(null);

  const editPermission = editLocationPermissions(activeLocation);

  const canClick = useSelector(activeUserHasPermission(editPermission));

  const selectedCountryType = useMemo(() => {
    return (
      COUNTRIES.find((c) => c.code === activeLocation.address.country) || null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLocation, parentAddress]);

  const resolveDisableLocationsAsyncAc = useMemo(() => {
    return (
      !!activeLocation.parentLocationId ||
      (!!activeLocation.id && activeLocation.id < 1)
    );
  }, [activeLocation]);

  const activeState = useMemo(() => {
    return (
      STATES.find((s) => s.abbreviation === activeLocation.address.state) ||
      null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLocation, parentAddress]);

  const verifyAddressButton = useMemo(() => {
    return !activeLocation.address.verified && !activeLocation.useParentAddress;
  }, [activeLocation.address.verified, activeLocation.useParentAddress]);

  const activeLocationAddressCountryCode = useMemo(() => {
    const selectedCountry =
      COUNTRIES.find((c) => c.code === activeLocation.address.country) || null;

    if (!selectedCountry) {
      return;
    }

    return selectedCountry.code.toLocaleLowerCase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLocation]);

  useEffect(() => {
    if (activeLocation.id !== null && firstInputElement.current !== null) {
      firstInputElement.current.focus();
    }
  }, [activeLocation.id]);

  useEffect(() => {
    const asyncFc = async (id: number) => {
      setIsLoading(true);
      try {
        const resLocation = await fetchLocation(id);
        oldState.current = resLocation;
        setActiveLocation(resLocation);
      } catch {
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
    };

    if (activeLocationId && activeLocationId > 0) {
      asyncFc(activeLocationId);
    } else {
      const newLocation: Location = {
        ...initialLocation,
        id: activeLocationId,
        address: {
          ...initialLocation.address,
          country: companySettings.country,
        },
      };
      oldState.current = newLocation;
      setActiveLocation(newLocation);
    }
    setValidationErrors({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLocationId]);

  useEffect(() => {
    if (!activeLocation.parentLocationId) {
      return;
    }

    const parentLocation = locations.find(
      (loc) => loc.id === activeLocation.parentLocationId
    );

    setParentAddress(_.get(parentLocation, 'address', initialAddress));
  }, [activeLocation.parentLocationId, locations]);

  const setAddress = useCallback((address: React.SetStateAction<Address>) => {
    if (typeof address === 'function') {
      setActiveLocation((l) => ({
        ...l,
        address: address(l.address),
      }));
      return;
    }

    setActiveLocation((l) => ({
      ...l,
      address,
    }));
  }, []);

  //Always set Location's name as location's address.name
  useEffect(() => {
    if (activeLocation.parentLocationId) {
      return;
    }
    setAddress((old) => ({ ...old, name: activeLocation.name }));
  }, [activeLocation.name, setAddress, activeLocation.parentLocationId]);

  const handleTextInputChanged = useHandleTextFieldChange<Location>(
    setActiveLocation,
    activeLocation
  );

  const handleAddressTextInputChanged = useHandleTextFieldChange<Address>(
    setAddress,
    { ...activeLocation.address, verified: false }
  );

  const handleNonVerifiedTextInputChange = useHandleTextFieldChange<Address>(
    setAddress,
    { ...activeLocation.address, verified: true }
  );

  const handleTypeChanged = useCallback((e: any, type: LocationType | null) => {
    setActiveLocation((old) => ({ ...old, type }));
  }, []);

  const handlePathChanged = useCallback(
    (v: Location | null) => {
      const parentLocationId = v ? v.id : null;

      // if useParentAddress is selected and we remove parentLocationId
      // we need to disable useParentAddress
      if (!parentLocationId && activeLocation.useParentAddress) {
        setActiveLocation((old) => {
          const addNewAddress =
            oldState.current!.useParentAddress && old.address.id !== null;

          return {
            ...old,
            parentLocationId,
            useParentAddress: false,
            address: addNewAddress ? initialAddress : old.address,
            addressId: addNewAddress ? initialAddress.id : old.id,
            parentLocation: null,
          };
        });
        return;
      }

      if (activeLocation.useParentAddress) {
        setActiveLocation((old) => ({
          ...old,
          parentLocationId,
          address: v ? v.address : initialAddress,
          parentLocation: v,
        }));

        return;
      }

      setActiveLocation((old) => ({
        ...old,
        parentLocationId,
        parentLocation: v,
      }));
    },
    [activeLocation.useParentAddress]
  );

  const handleCountryChanged = useCallback(
    (e: any, country: CountryType | null) => {
      setActiveLocation((old) => ({
        ...old,
        address: {
          ...old.address,
          country: country ? country.code : null,
          verified: false,
        },
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeLocation]
  );

  const handleStateChanged = useCallback(
    (e: any, state: StateType | null) => {
      setActiveLocation((old) => ({
        ...old,
        address: {
          ...old.address,
          state: state ? state.abbreviation : null,
          verified: false,
        },
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeLocation]
  );

  const handleResidentialChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setAddress((old) => ({ ...old, residential: checked, verified: false }));
    },
    [setAddress]
  );

  const handleSaveClicked = useCallback(
    (close: boolean = false) =>
      async () => {
        const isValid = validateYup(
          activeLocation,
          yupSchema,
          setValidationErrors
        );

        if (!isValid || !activeLocation.id) {
          return false;
        }

        setIsLoading(true);

        if (!activeLocation.id || activeLocation.id === -1) {
          try {
            if (!activeLocation.useParentAddress) {
              const newAddress = await postAddress(activeLocation.address);
              const newActiveLocation = await postLocation({
                ...activeLocation,
                addressId: newAddress.id,
              });
              oldState.current = newActiveLocation;
              setActiveLocation(newActiveLocation);
            } else {
              const newActiveLocation = await postLocation(activeLocation);
              oldState.current = newActiveLocation;
              setActiveLocation(newActiveLocation);
            }
          } catch {
            setIsLoading(false);
            return false;
          }

          await fetchSearchResult();
          // Lint skip to be removed
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dispatch(fetchLocations());

          if (close) {
            onClose();
          }

          setIsLoading(false);
          return true;
        }

        try {
          if (
            !activeLocation.useParentAddress &&
            oldState.current!.useParentAddress
          ) {
            // we need to create new address because original address is from parent
            const newAddress = await postAddress(activeLocation.address);
            const newActiveLocation = await putLocation({
              ...activeLocation,
              addressId: newAddress.id,
            });
            oldState.current = newActiveLocation;
            setActiveLocation(newActiveLocation);
          } else if (!activeLocation.useParentAddress) {
            // we need to update address
            await putAddress(activeLocation.address);
            const newActiveLocation = await putLocation(activeLocation);
            oldState.current = newActiveLocation;
            setActiveLocation(newActiveLocation);
          } else {
            // server deals with address
            const newActiveLocation = await putLocation(activeLocation);
            oldState.current = newActiveLocation;
            setActiveLocation(newActiveLocation);
          }
        } catch {
          setIsLoading(false);
          return false;
        }

        // Lint skip to be removed
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(fetchLocations());
        await fetchSearchResult();

        if (close) {
          onClose();
        }

        setIsLoading(false);
        return true;
      },
    [activeLocation, dispatch, fetchSearchResult, onClose]
  );

  const handleDeleteModalVisible = useCallback(
    (visible: boolean) => () => setDeleteModalVisible(visible),
    []
  );

  const handleDeleteConfirm = useCallback(async () => {
    try {
      await deleteLocation(activeLocation.id!);
      await fetchSearchResult();
    } catch {
      return;
    }

    setDeleteModalVisible(false);
    onClose();
  }, [activeLocation.id, fetchSearchResult, onClose]);

  const handleUndeleteClicked = useCallback(
    async (close: boolean = false) => {
      setIsLoading(true);

      try {
        await restoreLocation(activeLocationId!);
        const restoredLocation = await fetchLocation(activeLocationId!);
        oldState.current = restoredLocation;
        setActiveLocation(restoredLocation);
      } catch {
        setIsLoading(false);
        return false;
      }

      if (close) {
        onClose();
      }

      setIsLoading(false);
      fetchSearchResult();
      return true;
    },

    [fetchSearchResult, onClose, activeLocationId]
  );

  const handleShippingAddressChange = useCallback(
    (loc: Location | null) => {
      setActiveLocation((old) => ({
        ...old,
        defaultShippingLocation: loc ? loc.id : null,
      }));
    },
    [setActiveLocation]
  );

  const handleReceivingAddressChange = useCallback(
    (loc: Location | null) => {
      setActiveLocation((old) => ({
        ...old,
        defaultReceivingLocation: loc ? loc.id : null,
      }));
    },
    [setActiveLocation]
  );

  const handleParentAddress = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (!checked) {
        setActiveLocation((old) => {
          const addNewAddress =
            oldState.current!.useParentAddress && old.address.id !== null;

          return {
            ...old,
            useParentAddress: false,
            address: addNewAddress
              ? { ...initialAddress, country: parentAddress.country }
              : activeLocation.address,
            addressId: addNewAddress ? initialAddress.id : old.id,
          };
        });
        return;
      }

      setActiveLocation((old) => ({
        ...old,
        useParentAddress: true,
        address: old.parentLocation
          ? old.parentLocation.address
          : parentAddress,
        addressId: old.id,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [parentAddress]
  );

  const handleEmailBlurChange = useCallback(async () => {
    validateYup(activeLocation, emailBlurValidation, setValidationErrors);
  }, [activeLocation]);

  const handleAddressValidationModalClose = () => {
    setAddressValidationModalVisible(false);
  };

  const handleVerifyClick = useCallback(async () => {
    if (!connection) {
      return;
    }

    setIsLoading(true);

    try {
      const validatedAddress = await getValidateAddress(
        connection,
        activeLocation.address
      );

      if (!activeLocation.address.verified) {
        setAddressValidationModalVisible(true);
      }

      setAddressValidation(validatedAddress);
    } catch (error) {
      logErrorCtx('Error in getValidateAddress', {
        error: error as Error,
        component: 'LocationDetailsCard',
        description: `Connection ${connection}, Address ${activeLocation.address}`,
      });
    }

    setIsLoading(false);
  }, [connection, setIsLoading, activeLocation.address]);

  const onSaveVerified = useCallback(() => {
    setActiveLocation((old) => ({
      ...old,
      address: {
        ...old.address,
        ...addressValidation.validated,
        id: old.address.id,
        version: old.address.version,
        phone: old.address.phone,
        email: old.address.email,
        verified: true,
      },
    }));

    setAddressValidationModalVisible(false);

    showNotification('Address verified', { variant: 'success' });
  }, [addressValidation.validated]);

  return (
    <DetailsCard
      onSubmit={handleSaveClicked(false)}
      isLoading={isLoading}
      state={activeLocation}
      oldState={oldState}
    >
      <LocationTitleBar
        activeLocation={activeLocation}
        onSave={handleSaveClicked()}
        onClose={onClose}
        onUndeleteClicked={handleUndeleteClicked}
        deleteModalVisible={handleDeleteModalVisible(true)}
      />

      <Grid
        sx={{ overflowY: 'scroll' }}
        container
        direction="row"
        alignItems="flex-start"
        rowSpacing={{ xs: 2 }}
        columnSpacing={{ xs: 2 }}
        padding={{ xs: 4 }}
        disableEqualOverflow
      >
        <Grid xs={4}>
          <input hidden={true} name="address" />
          {/*This input is required to prevent Chrome autofill from overriding our settings*/}
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Name"
            placeholder="Enter name"
            name="name"
            autoComplete="nope"
            fullWidth
            permissions={editPermission}
            inputRef={firstInputElement}
            value={activeLocation.name}
            onChange={handleTextInputChanged}
            error={!!validationErrors.name}
            required
            dataQa="location-name"
          />
        </Grid>
        <Grid xs={4}>
          <Autocomplete
            label="Type"
            options={Object.values(LocationType)}
            getOptionLabel={(option: string) => option}
            value={activeLocation.type}
            onChange={handleTypeChanged}
            permissions={editPermission}
            placeholder="Select type"
            required
            dataQa="location-type"
          />
        </Grid>
        <Grid xs={4}>
          <LocationsAsyncAutocomplete
            label="Location Parent"
            value={activeLocation.parentLocationId}
            onChange={handlePathChanged}
            permissions={editPermission}
            placeholder="Select location parent"
            companyWide={false}
            activeId={activeLocation.id}
            customQuickSearchColumns={['name']}
            dataQa="location-parent"
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            className="redesign"
            variant="standard"
            label="Description"
            type="text"
            multiline
            minRows="3"
            placeholder="Add description"
            name="description"
            fullWidth
            permissions={editPermission}
            value={activeLocation.description}
            onChange={handleTextInputChanged}
            dataQa="location-description"
          />
        </Grid>
        <Grid xs={6}>
          <LocationsAsyncAutocomplete
            placeholder="Select default shipping location"
            onChange={handleShippingAddressChange}
            label="Default Shipping Location"
            value={activeLocation.defaultShippingLocation || null}
            parentId={activeLocation.id}
            permissions={editPermission}
            companyWide={false}
            dataQa="shipping-location"
            disabled={resolveDisableLocationsAsyncAc}
          />
        </Grid>
        <Grid xs={6}>
          <LocationsAsyncAutocomplete
            placeholder="Select default receiving location"
            onChange={handleReceivingAddressChange}
            label="Default Receiving Location"
            value={activeLocation.defaultReceivingLocation || null}
            permissions={editPermission}
            parentId={activeLocation.id}
            companyWide={false}
            dataQa="receiving-location"
            disabled={resolveDisableLocationsAsyncAc}
          />
        </Grid>
        <Grid xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                className="redesign"
                onChange={handleParentAddress}
                checked={activeLocation.useParentAddress}
                disabled={!activeLocation.parentLocationId}
                color="primary"
              />
            }
            disabled={!canClick || activeLocation.parentLocationId === null}
            label="Use Parent Address"
          />
        </Grid>
        <Grid xs={6}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Company Name"
            placeholder="Enter company name"
            name="companyName"
            permissions={editPermission}
            autoComplete="nope"
            fullWidth
            value={
              activeLocation.useParentAddress
                ? parentAddress.companyName
                : activeLocation.address.companyName
            }
            disabled={activeLocation.useParentAddress}
            onChange={
              activeLocation.address.verified
                ? handleNonVerifiedTextInputChange
                : handleAddressTextInputChanged
            }
            dataQa="location-company-name"
          />
        </Grid>
        <Grid xs={6}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Attention"
            permissions={editPermission}
            placeholder="Enter attention name"
            name="attention"
            autoComplete="nope"
            fullWidth
            value={
              activeLocation.useParentAddress
                ? parentAddress.attention
                : activeLocation.address.attention
            }
            disabled={activeLocation.useParentAddress}
            onChange={
              activeLocation.address.verified
                ? handleNonVerifiedTextInputChange
                : handleAddressTextInputChanged
            }
            dataQa="location-address-name"
          />
        </Grid>

        <Grid xs={6}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Address"
            placeholder="Enter address"
            name="street"
            autoComplete="nope"
            fullWidth
            permissions={editPermission}
            value={
              activeLocation.useParentAddress
                ? parentAddress.street
                : activeLocation.address.street
            }
            disabled={activeLocation.useParentAddress}
            onChange={handleAddressTextInputChanged}
            dataQa="location-address"
          />
        </Grid>
        <Grid xs={6}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Address 2"
            placeholder="Enter address"
            name="street2"
            autoComplete="nope"
            fullWidth
            permissions={editPermission}
            value={
              activeLocation.useParentAddress
                ? parentAddress.street2
                : activeLocation.address.street2
            }
            disabled={activeLocation.useParentAddress}
            onChange={handleAddressTextInputChanged}
            dataQa="location-address2"
          />
        </Grid>
        <Grid xs={4}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="City"
            placeholder="Enter city"
            name="city"
            autoComplete="nope"
            fullWidth
            permissions={editPermission}
            value={
              activeLocation.useParentAddress
                ? parentAddress.city
                : activeLocation.address.city
            }
            disabled={activeLocation.useParentAddress}
            onChange={handleAddressTextInputChanged}
            dataQa="location-city"
          />
        </Grid>
        <Grid xs={2}>
          {activeLocation.address.country === 'US' ? (
            <StateAutocomplete
              value={activeState}
              placeholder="Select state"
              onChange={handleStateChanged}
              error={!!validationErrors.state}
              disabled={activeLocation.useParentAddress}
            />
          ) : (
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="State"
              placeholder="Enter state"
              name="state"
              autoComplete="nope"
              fullWidth
              permissions={editPermission}
              value={
                activeLocation.useParentAddress
                  ? parentAddress.state
                  : activeLocation.address.state
              }
              disabled={activeLocation.useParentAddress}
              onChange={handleAddressTextInputChanged}
              dataQa="location-state"
            />
          )}
        </Grid>
        <Grid xs={2}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Zip"
            placeholder="Enter ZIP"
            name="postalCode"
            autoComplete="nope"
            fullWidth
            permissions={editPermission}
            value={
              activeLocation.useParentAddress
                ? parentAddress.postalCode
                : activeLocation.address.postalCode
            }
            disabled={activeLocation.useParentAddress}
            onChange={handleAddressTextInputChanged}
            dataQa="location-zip"
          />
        </Grid>
        <Grid xs={4}>
          <CountryAutocomplete
            value={selectedCountryType}
            disabled={activeLocation.useParentAddress}
            onChange={handleCountryChanged}
            permissions={editPermission}
            placeholder="Select country"
            required
          />
        </Grid>
        <Grid xs={4}>
          <PhoneInputField
            label="Phone"
            defaultCountry={activeLocationAddressCountryCode || undefined}
            permissions={editPermission}
            placeholder="Enter phone number"
            name="phone"
            autoComplete="nope"
            dropdownUpward
            fullWidth
            value={
              activeLocation.useParentAddress
                ? parentAddress.phone
                : activeLocation.address.phone
            }
            onChange={handleAddressTextInputChanged}
            dataQa="location-phone"
            error={!!validationErrors['address.phone']}
            helperText={
              validationErrors['address.phone'] ? 'Invalid phone number' : ''
            }
          />
        </Grid>
        <Grid xs={4}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Email"
            permissions={editPermission}
            placeholder="Enter email address"
            name="email"
            autoComplete="nope"
            fullWidth
            value={
              activeLocation.useParentAddress
                ? parentAddress.email
                : activeLocation.address.email
            }
            onChange={handleAddressTextInputChanged}
            dataQa="location-email"
            error={!!validationErrors['address.email']}
            onBlur={handleEmailBlurChange}
            helperText={
              validationErrors['address.email'] ? 'Invalid email' : ''
            }
          />
        </Grid>
        <Grid xs={4} paddingTop="38px">
          <FormControlLabel
            control={
              <Checkbox
                className="redesign"
                checked={Boolean(
                  activeLocation.useParentAddress
                    ? parentAddress.residential
                    : activeLocation.address.residential
                )}
                name="residential"
                color="primary"
                onChange={handleResidentialChanged}
                inputProps={
                  {
                    'data-qa': 'location-residential',
                  } as any
                }
              />
            }
            disabled={!canClick}
            label="Residential"
          />
        </Grid>
        {!!connection && activeLocation.address.country === 'US' && (
          <>
            <Grid xs={8}>
              {verifyAddressButton && (
                <FBOButton
                  color="positive"
                  variant="primary"
                  onClick={handleVerifyClick}
                  disabled={isLoading || !activeLocation.address.street}
                  data-qa="verify-address-button"
                >
                  Verify Address
                </FBOButton>
              )}
            </Grid>
            <Grid xs={4}>
              <VerifiedAddressLabel
                verifiedLabelVisible={activeLocation.address.verified}
              />
            </Grid>
          </>
        )}
      </Grid>

      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete Location"
        body={`This will delete '${_.get(
          activeLocation,
          'name',
          'Location'
        )}', are you sure?`}
        onCancelClicked={handleDeleteModalVisible(false)}
        onConfirmClicked={handleDeleteConfirm}
        confirmLabel="Delete"
        cancelLabel="Cancel"
        confirmButtonRed
      />
      <AddressValidationModal
        onClose={handleAddressValidationModalClose}
        modalVisible={addressValidationModalVisible}
        addressValidation={addressValidation}
        onSaveOriginal={handleAddressValidationModalClose}
        onSaveVerified={onSaveVerified}
      />
    </DetailsCard>
  );
};

export default FBOLocationDetailsCard;
