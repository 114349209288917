import { TaxType } from 'services/taxRates';
import * as yup from 'yup';

export const getTaxRateValidationSchema = (
  isInternational: boolean,
  isConnected: boolean,
  type: TaxType
) => {
  return yup.object().shape({
    name: yup.string().required().typeError('Name is required'),
    code: yup.string().nullable(false).required().typeError('Code is required'),
    agencyId: yup.number().required().typeError('Please select vendor'),
    percentage:
      type === TaxType.percentage
        ? yup.number().required().typeError('Amount is required')
        : yup.number(),
    amount:
      type === TaxType.flatRate
        ? yup.number().required().typeError('Amount is required')
        : yup.number(),
    mappedTaxName:
      isInternational && isConnected
        ? yup
            .string()
            .nullable(false)
            .required()
            .typeError('Mapped Tax is required')
        : yup.string().nullable(),
    channelTaxType: isInternational
      ? yup.string().nullable(false).required().typeError('Type is required')
      : yup.string().nullable(),
  });
};
