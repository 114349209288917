import React from 'react';

import { Routes } from '../../navigation';
import { AuthContainer } from '../../components';
import { RouteComponentProps } from 'react-router';
import { Box, Typography } from '@mui/material';
import { LoadingArrowButton } from 'ui/components/LoadingArrowButton';
import { useAuth0 } from '@auth0/auth0-react';
import { useAccountStatusPageStyle } from './styled';

interface AccountStatusPageCmp
  extends React.FunctionComponent<AccountStatusPageProps> {
  route: string;
}

interface AccountStatusPageProps extends RouteComponentProps {}

const AccountStatusPage: AccountStatusPageCmp = () => {
  const classes = useAccountStatusPageStyle();
  const { logout } = useAuth0();
  return (
    <AuthContainer
      title="Your Account has Expired"
      showNotACustomer={false}
      sx={{ alignSelf: 'auto !important', color: 'red' }}
    >
      <Box display="flex" flexDirection={'column'}>
        <Typography className={classes.textStyle} marginBottom={2}>
          Please contact your Account Manager to have your contract or trial
          extended.
        </Typography>
        <Typography className={classes.textStyle} marginBottom={4}>
          If you feel this may be an error you may attempt to log in again.
        </Typography>
        <LoadingArrowButton
          onClick={() =>
            logout({
              logoutParams: {
                returnTo: process.env.REACT_APP_AUTH0_CALLBACK_URL,
              },
            }).then()
          }
        >
          Re-attempt Login
        </LoadingArrowButton>
      </Box>
    </AuthContainer>
  );
};

AccountStatusPage.route = Routes.AccountStatusPage;

export default AccountStatusPage;
