import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { TableCell, Link } from '@mui/material';

import { Item, ItemType } from 'services/items';
import { showThumbnail } from 'services/thumbnail/redux';
import { FBOIconButtonWithActions } from 'ui/components/Button/IconButtonWithActions';
import { DateTooltip } from 'ui/components/DateTooltip';
import { DateMessage } from 'ui/components/DateTooltip/types';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { CostingMethod } from 'ui/modules/setup/pages/SettingsPage/components/CompanyCard/types';
import { IconNames } from 'ui/theme';
import { useCurrencyFormatter, useGetIntlDateFormatString } from 'helpers';

import { createActionBarOptions, ItemPageRowAction } from './consts';
import { useItemRowStyle } from './styled';
import { activeUserHasPermission } from 'services/userV2/redux';
import { PermissionType } from 'services/permissions';

const ItemRow: React.FC<RenderCustomRowProps<Item>> = (props) => {
  const {
    row,
    columns,
    onAction,
    meta: { companySettings },
  } = props;

  const currencyFormatter = useCurrencyFormatter();
  const intlFormatDate = useGetIntlDateFormatString();

  const classes = useItemRowStyle();

  const history = useHistory();

  const dispatch = useDispatch();

  const showCost = useSelector(
    activeUserHasPermission([PermissionType.InventoryCosting])
  );

  const handleOpenPreview = useCallback(
    (e) => {
      const imageUrl = _.get(row, 'defaultImage', '') as string;

      dispatch(showThumbnail(imageUrl));
      e.stopPropagation();
    },
    [dispatch, row]
  );

  const saleItem = useMemo(() => {
    return row.saleItemList.find((s) => s.defaultFlag) || null;
  }, [row]);

  const isBundleItem = useMemo(
    () => row.itemType === ItemType.Bundle,
    [row.itemType]
  );

  const isInventoryItem = useMemo(
    () => row.itemType === ItemType.Inventory,
    [row.itemType]
  );

  const handleAction = useCallback(
    (actionType: ItemPageRowAction) => {
      if (onAction) {
        onAction({ type: actionType, value: row });
      }
    },
    [onAction, row]
  );

  const tooltipDates: DateMessage[] = useMemo(
    () => [
      { date: row.dateLastModified, message: 'Date Last Modified' },
      { date: row.dateCreated, message: 'Date Created' },
    ],
    [row]
  );

  const dateValue: string = useMemo(() => {
    const dateField = columns.length > 1 ? columns[6].field! : null;
    const date = dateField ? _.get(row, dateField, null) : null;

    return date ? moment(date).format(intlFormatDate) : '';
  }, [columns, row, intlFormatDate]);

  const bundleCost = useMemo(() => {
    const itemsTotalCost = row.bundleItems.reduce((acc, i) => {
      const bundleItemCost = _.get(i, 'saleItem.item.cost', 0);

      return acc + bundleItemCost * i.quantity!;
    }, 0);

    return itemsTotalCost;
  }, [row.bundleItems]);

  return (
    <>
      <TableCell>
        <ImageItemBox
          source={_.get(row, 'defaultImage', null)}
          name={_.get(row, 'name', null)}
          description={_.get(row, 'description', null)}
          onClick={handleOpenPreview}
        />
      </TableCell>

      {columns.length > 1 && (
        <>
          <TableCell data-qa={`Barcode / UPC - ${row.name}`}>
            {row.upc}
          </TableCell>
          <TableCell data-qa={`SKU - ${row.name}`} title={row.sku || ''}>
            {_.truncate(row.sku || '', { length: 10 })}
          </TableCell>
          <TableCell data-qa={`Type - ${row.name}`}>{row.itemType}</TableCell>
          <TableCell data-qa={`Quantity - ${row.name}`}>
            {isInventoryItem ? (
              <Link
                onClick={() => history.push(getInventoryRoute(row.id))}
                variant="body2"
                color="textSecondary"
                underline="hover"
              >{`${row.totalQty} ${_.get(
                row,
                'defaultUom.abbreviation',
                ''
              )}`}</Link>
            ) : (
              '-'
            )}
          </TableCell>
          <TableCell
            data-qa={`Sale Item - ${row.name}`}
            className={classes.cell}
          >
            {saleItem ? saleItem.name : ''}
          </TableCell>
          <DateTooltip values={tooltipDates}>
            <TableCell data-qa={`Date Created - ${row.name}`}>
              {dateValue}
            </TableCell>
          </DateTooltip>
          <TableCell data-qa={`Price - ${row.name}`}>
            {isBundleItem
              ? currencyFormatter(row && row.price ? row.price : 0)
              : currencyFormatter(
                  saleItem && saleItem.price ? saleItem.price : 0
                )}
          </TableCell>
          {showCost &&
            companySettings.accountingMethod === CostingMethod.Average && (
              <TableCell data-qa={`Cost - ${row.name}`}>
                {isBundleItem
                  ? currencyFormatter(bundleCost || 0)
                  : currencyFormatter(row.cost || 0)}
              </TableCell>
            )}
          <TableCell data-qa={`Three dot menu - ${row.name}`} padding="none">
            <FBOIconButtonWithActions
              variant="secondary"
              color="neutral"
              size="small"
              noBorder
              icon={IconNames.IconKebab}
              items={createActionBarOptions(handleAction, row)}
              data-qa="item-table-three-dot-menu"
            />
          </TableCell>
        </>
      )}
    </>
  );
};

export default memo(ItemRow);

const getInventoryRoute = (id: number | null) => {
  return `/materials/tracking?advancedSearch%5Bid%5D=${id}&activeId=${id}`;
};
