import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { ShippoLogo } from 'ui/theme';
import {
  getShippoAuth0Url,
  getShippoAuthUrl,
} from 'services/integrations/shipping/shippo';
import {
  shippingConnectionDisconnect,
  ShippingConnectionType,
} from 'services/integrations/shipping';
import {
  getShippingIntegration,
  getShippingIntegrationConnection,
  fetchShippingIntegrationConnections,
} from 'services/integrations/shipping/redux';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { showNotification } from 'services/api';

import { ShippoCardProps } from './types';
import { Card } from '../';
import { CardButton } from '../IntegrationButtons/CardButton';
import { logErrorCtx } from 'app/logging';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'helpers/useFlags';

const ShippoCard: React.FC<ShippoCardProps> = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const flags = useFlags();

  const shippoConnection = useSelector(
    getShippingIntegrationConnection(ShippingConnectionType.Shippo)
  );
  const { _status, connections } = useSelector(getShippingIntegration);

  const [modalVisible, setModalVisible] = useState(false);
  const [disconnectLoading, setDisconnectLoading] = useState(false);

  const handleConnectClicked = async () => {
    if (connections.length > 0) {
      showNotification('You can only be connected to 1 shipping integration.', {
        variant: 'error',
      });
      return;
    }
    let url;
    if (flags.driveAuth0Enabled) {
      const accessToken = await getAccessTokenSilently();
      url = getShippoAuth0Url(accessToken);
    } else {
      url = getShippoAuthUrl();
    }
    window.location.href = url;
  };

  const handleDisconnectClicked = () => setModalVisible(true);

  const handleConfirmDisconnectClicked = async () => {
    setDisconnectLoading(true);

    try {
      await shippingConnectionDisconnect(shippoConnection!);
    } catch (e) {
      const error = e as Error;
      showNotification(
        _.get(error, 'response.data.message', 'Please contact support'),
        { variant: 'error' }
      );
      setDisconnectLoading(false);
      logErrorCtx('Shippo Disconnect Failure', {
        error,
        stackTrace: error.stack,
        title: 'Disconnect Confirmation Failed for Shippo',
        component: 'ShippoCard -> handleDisconnectClicked()',
      });
    }

    setDisconnectLoading(false);
    setModalVisible(false);
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchShippingIntegrationConnections());
  };

  return (
    <>
      <Card
        imageUrl={ShippoLogo}
        cardId="shippo"
        isLoading={_status.isLoading}
        description="Purchase and print shipping labels directly in Fishbowl with rates from USPS, UPS, FedEx, and other carriers to ensure you are always getting the best rates for your shipping needs."
        title="Shippo"
      >
        {!!shippoConnection ? (
          <CardButton
            onClick={handleDisconnectClicked}
            variant="secondary"
            color="negative"
            dataQa="shippo-disconnect"
            label="Disconnect"
          />
        ) : (
          <CardButton
            onClick={handleConnectClicked}
            variant="primary"
            color="positive"
            dataQa="shippo-connect"
            label="Connect"
          />
        )}
      </Card>
      <ConfirmationModal
        open={modalVisible}
        title="Disconnect Shippo integration?"
        onConfirmClicked={handleConfirmDisconnectClicked}
        onCancelClicked={() => setModalVisible(false)}
        body="You are about to disconnect Shippo integration from your account. Do you want to continue?"
        confirmButtonRed
        confirmLabel="Yes"
        isLoading={disconnectLoading}
      />
    </>
  );
};

export default memo(ShippoCard);
