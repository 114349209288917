import { makeStyles } from '@mui/styles';

export const useAccountStatusPageStyle = makeStyles(() => ({
  textStyle: {
    color: 'black',
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'SF Pro Text',
    textAlign: 'center',
  },
}));
