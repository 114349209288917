import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import Auth0Handler from './Auth0Handler';
import { Auth0WrapperProps } from './type';

/**
 * Provides authentication context via Auth0.
 */
export const Auth0Wrapper: React.FC<Auth0WrapperProps> = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
  const audience = process.env.REACT_APP_AUTH0_CLIENT_AUDIENCE || '';

  const authorizationParams = {
    redirect_uri: window.location.origin,
    audience,
  };
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={authorizationParams}
    >
      <Auth0Handler>{children}</Auth0Handler>
    </Auth0Provider>
  );
};

export default Auth0Wrapper;
