import React, { memo, useMemo, useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getVendors } from 'services/vendors';
import { Modal } from 'ui/components/Modal/Modal';
import { AutoPurchaseOrderItem, PurchaseOrder } from 'services/purchaseOrders';
import { Errors, validateYup } from 'services/forms/validation';
import { fetchItemsAPI } from 'services/items/api';
import { RequestConfig } from 'helpers';

import { CreatePurchaseOrderWizardProps } from './types';
import { getCreatePurchaseOrderWizardSteps } from './consts';
import { ItemsStep, OrdersStep } from './components';
import { rowSchema } from './validations';
import {
  createAutoPurchaseOrders,
  createPurchaseOrders,
  extractVendorId,
  resolveItems,
  resolveQuantity,
} from './helpers';
import { logErrorCtx } from 'app/logging';
import FBOWizard from 'ui/theme/components/FBOWizard/FBOWizard';

const CreatePurchaseOrderWizard: React.FC<CreatePurchaseOrderWizardProps> = (
  props
) => {
  const {
    visible,
    onClose,
    salesOrder: { locationId, salesItems },
  } = props;

  const { items: vendors } = useSelector(getVendors);

  const [activeStep, setActiveStep] = useState(0);
  const [autoPoItems, setAutoPoItems] = useState<AutoPurchaseOrderItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrder[]>([]);
  const [errors, setErrors] = useState<Errors[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const steps = useMemo(
    () => getCreatePurchaseOrderWizardSteps(activeStep),
    [activeStep]
  );

  const nextButtonDisabled = useMemo(
    () => activeStep === 0 && !selectedItems.length,
    [activeStep, selectedItems]
  );

  const validateAutoCompleteItems = useCallback(
    (autoItems: AutoPurchaseOrderItem[]) => {
      const newErrors: Errors[] = [];
      const itemsAreValid = autoItems.map((item, index) => {
        if (!selectedItems.includes(item.id)) {
          return true;
        }
        return validateYup(item, rowSchema, (error: Errors) => {
          newErrors[index] = error;
        });
      });
      setErrors(newErrors);

      return itemsAreValid.some((valid) => !valid);
    },
    [selectedItems]
  );
  useEffect(() => {
    (async () => {
      if (!visible) {
        return;
      }

      const mergedItems = resolveItems(salesItems);

      if (mergedItems.length) {
        try {
          const requestConfig: RequestConfig = {
            expands: [
              'images',
              'itemLocations',
              'defaultUom',
              'vendorItems.vendor',
            ],
          };
          const itemIds = mergedItems.map(
            (i) => (i.saleItem && i.saleItem.itemId) || 0
          );
          const items = (await fetchItemsAPI(requestConfig, [], itemIds)).data;
          const allSelectedItems = items.map((ai) => ai.id!);

          setAutoPoItems(
            items.map((i) => {
              return {
                id: i.id!,
                item: i,
                quantity: resolveQuantity(i.id, mergedItems),
                vendorId: extractVendorId(i),
                defaultUom: i.defaultUom,
                uom: i.defaultUom,
                uomId: i.defaultUomId,
              };
            })
          );
          setSelectedItems(allSelectedItems);
        } catch (e) {
          const error = e as Error;
          logErrorCtx('Error in Creating Purchase Order', {
            error,
            stackTrace: error.stack,
            title: 'Error when Creating Purchase Order',
            description: 'Unable to Create Purchase Order',
            component: 'CreatePurchaseOrderWizard',
          });
        }
      }
    })();
  }, [visible, salesItems]);

  const handleClose = useCallback(() => {
    onClose();
    setActiveStep(0);
    setSelectedItems([]);
    setAutoPoItems([]);
  }, [onClose]);

  const finishWizard = useCallback(async () => {
    try {
      await createPurchaseOrders(purchaseOrders);
    } catch (e) {
      const error = e as Error;
      logErrorCtx('Failed while Creating Purchase Order ', {
        error,
        stackTrace: error.stack,
        title: 'Purchase order creation failure.',
        description:
          'An error occured while trying to create a Purchase order in Sales Orders.',
        component: 'SalesOrderDetailsCard -> CreatePurchaseOrderWizard',
      });
    }
    handleClose();
  }, [purchaseOrders, handleClose]);

  const resolvePurchaseOrders = useCallback(async () => {
    const resolvedItems = autoPoItems.filter((i) =>
      selectedItems.includes(i.id)
    );

    if (!locationId) {
      return;
    }

    try {
      setIsLoading(true);
      const newOrders = await createAutoPurchaseOrders(
        resolvedItems,
        locationId,
        vendors
      );
      setPurchaseOrders(newOrders);
    } catch (e) {
      const error = e as Error;

      logErrorCtx('Error in creating purchaseOrder', {
        error,
        stackTrace: error.stack,
        title: 'Error when creating purchaseOrder',
        description: 'Failed to create purchase order in sales order page.',
        component: 'SalesOrderPage-> CreatePurchaseOrderWizard',
      });
    }
    setIsLoading(false);
  }, [selectedItems, locationId, vendors, autoPoItems]);

  const handleNextClicked = useCallback(() => {
    if (activeStep === 0) {
      const isInvalid = validateAutoCompleteItems(autoPoItems);
      if (isInvalid) {
        return;
      }
      resolvePurchaseOrders();
    }
    if (activeStep === steps.length - 1) {
      finishWizard();
      return;
    }
    setActiveStep(activeStep + 1);
  }, [
    activeStep,
    steps,
    autoPoItems,
    finishWizard,
    validateAutoCompleteItems,
    resolvePurchaseOrders,
  ]);

  const handleBackClicked = useCallback(() => {
    setActiveStep(activeStep - 1);
    setErrors([]);
  }, [activeStep]);

  return (
    <Modal
      open={visible}
      onCancelClicked={handleClose}
      title="Create Purchase Order"
      scroll="paper"
      isLoadingContent={isLoading}
      withoutFooter
      withoutDefaultPadding
    >
      <FBOWizard
        steps={steps}
        activeStep={activeStep}
        onNext={handleNextClicked}
        onPrevious={handleBackClicked}
        finishButtonLabel="Create Orders"
        disableNext={nextButtonDisabled}
        hideSteps
      >
        {[
          <ItemsStep
            autoPoItems={autoPoItems}
            setAutoPoItems={setAutoPoItems}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            errors={errors}
            locationId={locationId}
            key={1}
          />,
          <OrdersStep purchaseOrders={purchaseOrders} key={2} />,
        ]}
      </FBOWizard>
    </Modal>
  );
};

export default memo(CreatePurchaseOrderWizard);
