import React, { memo, useCallback } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { TextField } from 'ui/components/TextField/TextField';
import {
  useHandleCheckboxChange,
  useHandleTextFieldChange,
} from 'services/forms/hooks';
import { Ship } from 'services/shipping';
import { ShipAsyncAutocomplete } from 'ui/components/Autocomplete/ShipAutocomplete';

import { ReportFiltersProps } from '../../types';
import { ContactToShowOptions, OrderNumberOptions } from './types';

const PackingListFilters: React.FC<ReportFiltersProps> = (props) => {
  const { parameters, setParameters, errors } = props;

  const handleShipChange = useCallback(
    (value: Ship | null) =>
      setParameters((old) => ({ ...old, shipId: value ? value.id : null })),
    [setParameters]
  );

  const handleSelectAll = useCallback(
    (name: string) =>
      (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (name === 'optional') {
          setParameters((old) => ({
            ...old,
            showBackOrderedItems: checked,
            showShippingService: checked,
            showBarcode: checked,
            showCartonInfo: checked,
            showCompanyName: checked,
            showOrderNotes: checked,
            showTracking: checked,
            showFobPoint: checked,
            showDateScheduled: checked,
            showPackageCount: checked,
            selectAllOptional: checked,
          }));
        } else {
          setParameters((old) => ({
            ...old,
            columnDescription: checked,
            columnQtyOrdered: checked,
            columnQtyShipped: checked,
            columnLineNumber: checked,
            columnItemNumber: checked,
            columnQtyRemaining: checked,
            selectAllColumns: checked,
          }));
        }
      },
    [setParameters]
  );

  const handleCheckboxChange = useHandleCheckboxChange(setParameters);

  const handleTextFieldChange = useHandleTextFieldChange(
    setParameters,
    parameters
  );

  const handleContactToShowChange = useCallback(
    (e: any, contactToShow: ContactToShowOptions | null) =>
      setParameters((old) => ({ ...old, contactToShow })),
    [setParameters]
  );

  const handleOrderNumberChange = useCallback(
    (e: any, orderNumber: OrderNumberOptions | null) =>
      setParameters((old) => ({ ...old, orderNumber })),
    [setParameters]
  );
  // TODO : RESTYLING : Remove box wrappers around inputs after FF removal

  return (
    <>
      <Accordion square defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          data-qa="packing-list-required-accordion"
        >
          <Typography>
            <b>Required</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            <Box>
              <ShipAsyncAutocomplete
                label="Ship Number"
                value={parameters.shipId}
                placeholder="Select Ship"
                onChange={handleShipChange}
                required
                error={!!errors.shipId}
                helperText={errors.shipId}
                dataQa="packing-list-ship-number"
                customQuickSearchColumns={['number']}
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Box px={2} pb={0} pt={1}>
        <FormControlLabel
          className="redesign"
          sx={{ marginTop: '8px' }}
          control={
            <Checkbox
              className="redesign"
              checked={Boolean(parameters.selectAllOptional)}
              onChange={handleSelectAll('optional')}
              color="primary"
              data-qa="packing-list-select-all-optional"
            />
          }
          label="Select All Optional"
        />
      </Box>
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          data-qa="packing-list-optional-accordion"
        >
          <Typography>
            <b>Optional</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%" pt={1}>
            <Box>
              <Autocomplete
                label="Contact to Show"
                options={Object.values(ContactToShowOptions)}
                value={parameters.contactToShow || null}
                onChange={handleContactToShowChange}
                placeholder="Select"
                dataQa="packing-list-contact-to-show"
              />
            </Box>
            <Box>
              <Autocomplete
                label="Order Number"
                options={Object.values(OrderNumberOptions)}
                value={parameters.orderNumber || null}
                onChange={handleOrderNumberChange}
                placeholder="Select"
                dataQa="packing-list-order-number"
              />
            </Box>
            <Box>
              <TextField
                className="redesign"
                variant="standard"
                label="Report Title"
                placeholder="Report Title"
                name="title"
                value={parameters.title || null}
                onChange={handleTextFieldChange}
                fullWidth
                dataQa="packing-list-report-title"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showBackOrderedItems)}
                    onChange={handleCheckboxChange}
                    name="showBackOrderedItems"
                    color="primary"
                    data-qa="packing-list-show-back-ordered-items"
                  />
                }
                label="Show Backordered Items"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showShippingService)}
                    onChange={handleCheckboxChange}
                    name="showShippingService"
                    color="primary"
                    data-qa="packing-list-show-shipping-service"
                  />
                }
                label="Show Shipping Service"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showBarcode)}
                    onChange={handleCheckboxChange}
                    name="showBarcode"
                    color="primary"
                    data-qa="packing-list-show-barcode"
                  />
                }
                label="Show Barcode"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showCartonInfo)}
                    onChange={handleCheckboxChange}
                    name="showCartonInfo"
                    color="primary"
                    data-qa="packing-list-show-package-info"
                  />
                }
                label="Show Package Info"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showCompanyName)}
                    onChange={handleCheckboxChange}
                    name="showCompanyName"
                    color="primary"
                    data-qa="packing-list-show-company-name"
                  />
                }
                label="Show Company Name"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showOrderNotes)}
                    onChange={handleCheckboxChange}
                    name="showOrderNotes"
                    color="primary"
                    data-qa="packing-list-show-order-notes"
                  />
                }
                label="Show Order Notes"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showTracking)}
                    onChange={handleCheckboxChange}
                    name="showTracking"
                    color="primary"
                    data-qa="packing-list-show-tracking"
                  />
                }
                label="Show Tracking"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showFobPoint)}
                    onChange={handleCheckboxChange}
                    name="showFobPoint"
                    color="primary"
                    data-qa="packing-list-show-shipping-service"
                  />
                }
                label="Show FOB Point"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showDateScheduled)}
                    onChange={handleCheckboxChange}
                    name="showDateScheduled"
                    color="primary"
                    data-qa="packing-list-show-shipping-service"
                  />
                }
                label="Show Date Scheduled"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showPackageCount)}
                    onChange={handleCheckboxChange}
                    name="showPackageCount"
                    color="primary"
                    data-qa="packing-list-show-shipping-service"
                  />
                }
                label="Show Package Count"
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Box px={2} pb={0} pt={1}>
        <FormControlLabel
          className="redesign"
          sx={{ marginTop: '8px' }}
          control={
            <Checkbox
              className="redesign"
              checked={Boolean(parameters.selectAllColumns)}
              onChange={handleSelectAll('columns')}
              color="primary"
              data-qa="packing-list-select-all-columns"
            />
          }
          label="Select All Columns"
        />
      </Box>
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          data-qa="packing-list-columns-accordion"
        >
          <Typography>
            <b>Columns</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnDescription)}
                    onChange={handleCheckboxChange}
                    name="columnDescription"
                    color="primary"
                    data-qa="packing-list-column-description"
                  />
                }
                label="Description"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnQtyOrdered)}
                    onChange={handleCheckboxChange}
                    name="columnQtyOrdered"
                    color="primary"
                    data-qa="packing-list-column-qty-ordered"
                  />
                }
                label="Qty Ordered"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnQtyShipped)}
                    onChange={handleCheckboxChange}
                    name="columnQtyShipped"
                    color="primary"
                    data-qa="packing-list-column-qty-shipped"
                  />
                }
                label="Qty Shipped"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnLineNumber)}
                    onChange={handleCheckboxChange}
                    name="columnLineNumber"
                    color="primary"
                    data-qa="packing-list-column-line-number"
                  />
                }
                label="Line Number"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnItemNumber)}
                    onChange={handleCheckboxChange}
                    name="columnItemNumber"
                    color="primary"
                    data-qa="packing-list-column-item-number"
                  />
                }
                label="Item Number"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnQtyRemaining)}
                    onChange={handleCheckboxChange}
                    name="columnQtyRemaining"
                    color="primary"
                    data-qa="packing-list-column-qty-remaining"
                  />
                }
                label="Qty Remaining"
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default memo(PackingListFilters);
