import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, FormControlLabel, Checkbox, Link } from '@mui/material';
import { isEmpty } from 'lodash';
import { FBOTextField } from 'ui/theme/components';
import { LoadingArrowButton } from 'ui/components/LoadingArrowButton';
import {
  LoginFormValues,
  initialLoginForm,
  logIn,
  AuthActionTypes,
  setAccessToken,
} from 'services/auth';
import { Errors } from 'services/forms/validation';
import { enableTestEnv } from 'services/api/testEnv';
import { getCompanySettings } from 'services/settings/company';
import { isProd } from 'helpers/environment';

const emailRegExp = /(\W|^)[\w.-]{1,50}@(.+)\..+(\W|$)/;
const VALIDATION_SCHEMA = (key: string = '', value: string = ''): string => {
  let error = '';
  switch (key) {
    case 'email':
      if (isEmpty(value)) {
        error = 'Email is required';
      } else if (!value.match(emailRegExp)) {
        error = 'Invalid email';
      }
      break;
    case 'password':
      if (isEmpty(value)) error = 'Password is required';
      break;
  }
  return error;
};

/**
 * checks obj to see if there is any key that has a value
 */
const hasValues = (obj: Errors | LoginFormValues): boolean =>
  !!Object.values(obj).find((e: string) => e !== '');

interface LoginFormProps {
  afterLogin(): void;
}

export const LoginForm: React.FC<LoginFormProps> = ({ afterLogin }) => {
  const [formValues, setFormValues] =
    useState<LoginFormValues>(initialLoginForm);
  const [errors, setErrors] = useState<Errors>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const handleFormSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (!validateForm()) return;

    setIsLoading(true);
    try {
      const resp = await logIn(formValues);
      // Save token to redux
      dispatch({
        type: AuthActionTypes.SIGN_IN_SUCCESS,
        payload: { token: resp.token },
      });
      // Set token to local or session storage
      setAccessToken(resp.token);
      const { goLiveDate } = await getCompanySettings();
      if (!goLiveDate) {
        enableTestEnv();
      }
      afterLogin();
    } catch (e) {
      // todo : handle this
      // Ignore error
    }
    setIsLoading(false);
  };

  const validateForm = (): boolean => {
    const { email, password } = formValues;

    const formErrors = {
      email: VALIDATION_SCHEMA('email', email),
      password: VALIDATION_SCHEMA('password', password),
    };
    setErrors({ ...formErrors });

    if (hasValues(formErrors)) return false;

    return true;
  };

  const validateInput = (input: { name: string; value: string }): string => {
    const name = input.name;
    const value = input.value;
    return VALIDATION_SCHEMA(name, value);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { name, checked, type, value } = event.target;
    setErrors({ ...errors, [name]: '' });

    const resolvedValue = type === 'checkbox' ? !!checked : value;
    setFormValues((old) => ({
      ...old,
      [name]: resolvedValue,
    }));
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    const { name } = event.target;
    const { value } = event.target;
    const error = validateInput({ name, value });
    if (error) setErrors({ ...errors, [name]: error });
  };

  const hasErrors = useMemo(() => hasValues(errors), [errors]);

  return (
    <form onSubmit={handleFormSubmit} noValidate>
      <Grid className="redesign" container>
        <Grid item xs={12}>
          <FBOTextField
            autoFocus
            required
            name="email"
            className="redesign"
            type="text"
            label="Email"
            value={formValues.email}
            error={!!errors.email}
            errorText={errors.email}
            dataQa="login-user-name"
            allowLastPassIcon
            onChange={handleInputChange}
            onBlur={handleBlur}
            tabIndex={1}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            position: 'relative',
            marginBottom: '3px',
          }}
        >
          <Link
            className="redesign"
            variant="body1"
            component={RouterLink}
            to="/forgot-password"
            underline="none"
            sx={{
              fontSize: '12px !important',
              position: 'absolute',
              top: '-3px',
              right: '0',
            }}
            data-qa="forgot-pw-link"
            tabIndex={5}
          >
            Forgot Password?
          </Link>
          <FBOTextField
            required
            name="password"
            className="redesign"
            type="password"
            label="Password"
            value={formValues.password}
            error={!!errors.password}
            errorText={errors.password}
            dataQa="login-password"
            allowLastPassIcon
            onChange={handleInputChange}
            onBlur={handleBlur}
            tabIndex={2}
          />
        </Grid>
        <Grid item xs={12} mb={'32px !important'}>
          <FormControlLabel
            className="redesign"
            control={
              <Checkbox
                name="keepLoggedIn"
                className="redesign"
                size="small"
                checked={formValues.keepLoggedIn}
                onChange={handleInputChange}
                color="primary"
                inputProps={
                  {
                    'data-qa': 'login-keep-me-logged-in',
                  } as any
                }
                disableRipple
              />
            }
            label="Keep me logged in"
            sx={{
              // todo : move after redesign
              '& .MuiFormControlLabel-label': { fontSize: '14px' },
              marginBottom: '18px',
            }}
            tabIndex={3}
          />
        </Grid>
        <Grid
          item
          pt={'0px !important'}
          xs={12}
          // todo : move after re-style
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadingArrowButton
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            disabled={hasErrors || isLoading}
            data-qa="login-button"
            id="login-button"
            loadingPosition="start"
            loading={isLoading}
            disableRipple
            tabIndex={4}
          >
            Log In
          </LoadingArrowButton>

          {isProd() ? (
            <Grid mt={'16px'}>
              Don't have an account?&nbsp;
              <Link
                className="redesign"
                variant="body1"
                href={'https://www.fishbowlinventory.com/drive-create-account'}
                underline="none"
                sx={{ fontSize: '14px' }}
                data-qa="create-account-link"
              >
                Create account
              </Link>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginForm;
