import { getAccessToken } from 'services/auth';

export const getShippoAuthUrl = () => {
  const token = getAccessToken();

  const clientId = process.env.REACT_APP_SHIPPO_CLIENT_ID || '';

  return `https://goshippo.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=*&state=${token}`;
};
export const getShippoAuth0Url = (token: string) => {
  const clientId = process.env.REACT_APP_SHIPPO_CLIENT_ID || '';

  return `https://goshippo.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=*&state=${token}`;
};
