import axios, { AxiosResponse } from 'axios';
import { tenantIdHeaderInterceptor } from '../services/api/config';
import {
  AuthActionTypes,
  clearAccessToken,
  setAccessToken,
} from '../services/auth';
import { store } from '../app/redux';
import decode from 'jwt-decode';

export function addLegacyAuthInterceptors(): void {
  axios.interceptors.request.use(tenantIdHeaderInterceptor);
  // when we receive 401 error, we sign out the logged in user
  axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: any) => {
      // prettier-ignore
      if (
        error.response &&
        error.response.status === 401 &&
        !error.response.config.url.includes('fboshipping.initdevelopment.com') &&
        !error.response.config.url.includes('ship-dev.fishbowlonline.com') &&
        !error.response.config.url.includes('ship.fishbowlonline.com') &&
        !error.response.config.url.includes(
          'fishbowlonline.com/v1/admin/login_as'
        )
      ) {
        clearAccessToken();
        store.dispatch({
          type: AuthActionTypes.SIGN_OUT_ERROR,
          payload: error,
        });
      }
      if (error instanceof Error) {
        return Promise.reject(error);
      }
      return Promise.reject(new Error(error));
    }
  );
}

export function addAuth0Interceptors(
  accessToken: string,
  logout: (options?: { logoutParams: { returnTo?: string } }) => Promise<void>
): void {
  // decode only one time
  const token = decode<{ [key: string]: string }>(accessToken);
  const foTenant =
    token[process.env.REACT_APP_AUTH0_CLAIM_DOMAIN_PREFIX + 'fbo_tenant'];
  const tenantIdRequestInterceptor = (config: any) => {
    config.headers['Tenant-Id'] = foTenant;
    return config;
  };
  const responseErrorHandler = (error: any) => {
    // prettier-ignore
    if (
        error?.response &&
        error?.response?.status === 401 &&
        !error.response.config.url.includes('fboshipping.initdevelopment.com') &&
        !error.response.config.url.includes('ship-dev.fishbowlonline.com') &&
        !error.response.config.url.includes('ship.fishbowlonline.com') &&
        !error.response.config.url.includes('fishbowlonline.com/v1/admin/login_as')
      ) {
        logout({ logoutParams: { returnTo: process.env.REACT_APP_AUTH0_CALLBACK_URL } }).then();
      }
    if (error instanceof Error) {
      return Promise.reject(error);
    }
    return Promise.reject(new Error(error));
  };
  setAccessToken(
    accessToken,
    2,
    tenantIdRequestInterceptor,
    responseErrorHandler
  );
}
