import { PermissionGroup, PermissionType } from 'services/permissions';
import { CompanySettings } from 'services/settings/company';

import { Location } from '../locations';

export interface User {
  id: number | null;
  admin: boolean;
  dateCreated: Date | null;
  dateLastModified: Date | null;
  defaultContact: boolean;
  defaultLocation: Location | null;
  defaultLocationId: number | null;
  deleted: boolean;
  email: string | null;
  phoneNumber: string | null;
  phoneType: string | null;
  firstName: string | null;
  lastName: string | null;
  permissionGroups: PermissionGroup[];
  registered: boolean | null;
  timezone: string | null;
  useEmail: boolean | null;
  version: number | null;
  tenant: string | null;
  name: string | null;
  tenantAccountType: TenantAccountType | null;
}

export interface ActiveUser {
  currencyCode: string | null;
  environment: string | null;
  tenant: string | null;
  user: User | null;
  userGlobalId: number | null;
  permissions: PermissionType[];
  isAdmin: boolean;
  externalAccountId: string | null;
  externalContactId: string | null;
  isExecutor: boolean;
  companyExpirationDate: Date | null;
  maxIntegrations: number | null;
}
export interface GlobalUser {
  id: number | null;
  nameFirst: string | null;
  nameLast: string | null;
  email: string | null;
  companyId: number | null;
  company: CompanySettings | null;
  name: string | null;
  tenant: string | null;
  tenantAccountType: TenantAccountType | null;
  dateLoginAttempt: string | null;
}

export interface CrmAssociation {
  id: number | null;
  crmId: string | null;
  crmType: string | null;
}
export enum PhoneTypeOptions {
  Mobile = 'Mobile',
  Office = 'Office',
  Home = 'Home',
}
export enum TenantAccountType {
  TRIAL = 'TRIAL',
  PARTNER = 'PARTNER',
  CUSTOMER = 'CUSTOMER',
  INTERNAL = 'INTERNAL',
  CONSULTANT = 'CONSULTANT',
  PREVIOUSCUSTOMER = 'PREVIOUS_CUSTOMER',
}
