import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { withSearchResults } from 'ui/components/Page/WithSearchResults';
import { PageWithAdvancedSearch } from 'ui/components/Page/PageWithAdvancedSearch';
import { Dates, getErrorMessage } from 'helpers';
import {
  transformTaxRate,
  TaxRate,
  fetchSearch,
  getSearches,
  Channel,
} from 'services/taxRates';
import { Pagination } from 'services/search';
import { useUrlQueryObject } from 'services/url';
import { fetchVendors, getVendors } from 'services/vendors';
import { fetchPaymentTerms } from 'services/paymentTerms';

import {
  TaxRatesSearchResults,
  TaxRateDetailCard,
  TaxRatesAdvancedSearch,
} from './components';
import { Routes } from '../../navigation';
import { TaxRatesPageProps, TaxRatesPageCmp } from './types';
import {
  advancedSearchReduxActions,
  displayNameMap,
  initialTaxRateASFormValues,
  TAX_RATE_COLUMNS,
  initialPagination,
  createDisplayValueMap,
  TaxRatesPageAction,
} from './consts';
import { getSettingsCompany } from 'services/settings/company';
import { getXeroAccountIsConnected } from 'services/integrations/xero';
import { getQuickbooksAccountIsConnected } from 'services/integrations/quickbooks';
import {
  BackgroundAction,
  BackgroundType,
  startBackgroundExport,
  startBackgroundImport,
  useBackgroundTasks,
} from 'services/backgroundTasks';
import {
  showDownloadNotification,
  showLoadingNotification,
} from 'services/api/notifications/';
import { showProgressAlert } from 'services/alert/redux';
import { logErrorCtx } from 'app/logging';
const TaxRatesPage: TaxRatesPageCmp = (props: TaxRatesPageProps) => {
  const {
    searchState: searchResult,
    refreshSearchState: fetchSearchResult,
    isLoadingSearchState: isLoadingSearchResult,
    activeItemId: activeTaxRateId,
  } = props;

  const { items: vendors } = useSelector(getVendors);

  const [activeDate, setActiveDate] = useState<Dates>(Dates.DateCreated);
  const [, extendUrlQuery] = useUrlQueryObject();
  const countryCode = useSelector(getSettingsCompany);
  const xeroConnected = useSelector(getXeroAccountIsConnected);
  const qboConnected = useSelector(getQuickbooksAccountIsConnected);

  const [isInternational, setIsInternational] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  const [accountingType, setAccountingType] = useState(Channel.None);
  const { startFetching, startCsvFetching } = useBackgroundTasks();
  const dispatch = useDispatch();
  const hiddenInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (countryCode.country !== 'US') {
      setIsInternational(true);
    } else {
      setIsInternational(false);
    }

    if (xeroConnected) {
      setIsConnected(true);
      setAccountingType(Channel.Xero);
    } else if (qboConnected) {
      setIsConnected(true);
      setAccountingType(Channel.Qbo);
    } else {
      setIsConnected(false);
      setAccountingType(Channel.None);
    }
  }, [xeroConnected, qboConnected, countryCode]);
  const handlePaginationChange = useCallback(
    (newPagination: Pagination) => {
      fetchSearchResult({ pagination: newPagination });
    },
    [fetchSearchResult]
  );

  const handleActiveTaxRateClose = () => extendUrlQuery({ activeId: null });

  const handleAddNewPress = useCallback(() => {
    extendUrlQuery({ activeId: -1 });
  }, [extendUrlQuery]);
  const exportTaxRates = useCallback(async () => {
    showDownloadNotification();
    try {
      await startBackgroundExport(BackgroundType.Tax);
      startFetching();
    } catch {
      // continue regardless of error
    }
  }, [startFetching]);
  const importTaxRates = useCallback(() => {
    hiddenInput.current!.click();
  }, [hiddenInput]);

  const handleHiddenInput = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }

      const uploadedFile = event.target.files[0];
      showLoadingNotification('Your Tax rates import has been initiated.');

      startCsvFetching();
      event.target.value = '';
      try {
        await startBackgroundImport(BackgroundType.Tax, uploadedFile);
        dispatch(
          showProgressAlert(
            'Your Import to Tax rates has been initiated. This may take a few minutes to complete.',
            BackgroundType.Tax,
            BackgroundAction.Import
          )
        );
      } catch (e) {
        const message = getErrorMessage(e);
        const error = e as Error;
        logErrorCtx('Error in startBackgroundImport', {
          stackTrace: error.stack,
          error,
          description: message,
          component: 'TaxRatesPage',
        });
        return;
      }
    },
    [dispatch]
  );
  const handleTaxRateClicked = (id: number | null) =>
    extendUrlQuery({ activeId: id });

  const handlePageAction = useCallback(
    (action: TaxRatesPageAction, date: Dates) => {
      switch (action) {
        case TaxRatesPageAction.ChangeDate:
          setActiveDate(date);
          break;
        case TaxRatesPageAction.Export:
          exportTaxRates();
          break;
        case TaxRatesPageAction.Import:
          importTaxRates();
      }
    },
    [importTaxRates, exportTaxRates]
  );

  return (
    <>
      <PageWithAdvancedSearch
        detailCardColumns={TAX_RATE_COLUMNS(activeDate)}
        initialFormValues={initialTaxRateASFormValues}
        advancedSearchReduxActions={advancedSearchReduxActions}
        searchResult={searchResult}
        fetchSearchResult={fetchSearchResult}
        AdvancedSearchFieldsCmp={TaxRatesAdvancedSearch}
        displayNameMap={displayNameMap}
        displayValueMap={createDisplayValueMap(vendors)}
        pageName="Tax Rates"
      >
        <PaperSlidingLayout shown={Boolean(activeTaxRateId)}>
          <TaxRatesSearchResults
            taxRates={searchResult.items}
            activeTaxRateId={activeTaxRateId}
            activeDate={activeDate}
            onPageAction={handlePageAction}
            handleTaxRateClick={handleTaxRateClicked}
            onAddNewPress={handleAddNewPress}
            isLoadingTaxRates={isLoadingSearchResult}
            pagination={searchResult.pagination || initialPagination}
            onPaginationChange={handlePaginationChange}
          />
          <TaxRateDetailCard
            activeTaxRateId={activeTaxRateId}
            fetchSearchResult={fetchSearchResult}
            onClose={handleActiveTaxRateClose}
            isInternational={isInternational}
            isConnected={isConnected}
            accountingType={accountingType}
          />
        </PaperSlidingLayout>
      </PageWithAdvancedSearch>
      <input
        type="file"
        ref={hiddenInput}
        style={{ display: 'none' }}
        onChange={handleHiddenInput}
        accept=".csv"
      />
    </>
  );
};

TaxRatesPage.route = Routes.TaxRatesPage;

export default withSearchResults<TaxRate>(TaxRatesPage, {
  url: '/v1/taxes',
  dataAdapter: transformTaxRate,
  columns: TAX_RATE_COLUMNS(Dates.DateCreated),
  fetchSearch,
  getSearches,
  initialPagination,
  rehydrationThunks: [fetchVendors, fetchPaymentTerms],
});
